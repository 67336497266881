import { TodayOutlined } from '@mui/icons-material';
import { Grid, Typography, Paper, TextField, Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Container } from '../Home/index.style';
import { useLocation, useNavigate } from 'react-router';
import {
  UseMutationOptions,
  useMutation,
  useQuery
} from '@tanstack/react-query';
import {
  GradientBackgroundContainer,
  MainHeader
} from '../Checkout/index.style';
import { LoginSubContainer } from './index.style';
import FloorPlan from '../../Components/FloorPlan';

enum CHECKOUT_STATUS {
  PHONE_CHECKED = 'PHONE_CHECKED',
  OTP_CHECKED = 'OTP_CHECKED',
  NAME_CHECKED = 'NAME_CHECKED'
}

const seconds = 30;
const Login = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [name, setName] = useState('');
  const [timeLeft, setTimeLeft] = useState(seconds);

  const quanAndValue = useMemo(() => {
    const res = {
      quantity: 0,
      amount: 0
    };
    state?.allTicketsWithPrice?.forEach((item: any) => {
      res.quantity += item.quantity;
      res.amount += item.amount;
    });

    return res;
  }, [state?.allTicketsWithPrice]);

  //@ts-ignore
  const {
    mutate: generateCodeMutate,
    data: generateCodeData,
    isError: generateCodeError,
    isPending: generateCodePending
  } = useMutation({
    mutationFn: (formData: any) => {
      return fetch('https://api.sociana.in/api/v3/generate_code', {
        method: 'POST',
        body: formData
      }).then((res) => res.json());
    }
  });

  //occasion_codes/join_with_code

  const {
    mutate: loginMutate,
    data: loginData,
    isError: loginError,
    isPending: loginPending
  } = useMutation({
    mutationFn: (formData: any) => {
      return fetch('https://api.sociana.in/api/v3/sessions', {
        method: 'POST',
        body: formData
      }).then((res) => res.json());
    }
  });

  const {
    mutate: joinWithCodeMutate,
    data: joinWithCodeData,
    isError: joinWithCodeError,
    isPending: joinWithCodePending
  } = useMutation({
    mutationFn: (formData: any) => {
      return fetch(
        'https://api.sociana.in/api/v1/occasion_codes/join_with_code',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${loginData.meta?.token}`
          }
        }
      ).then((res) => res.json());
    }
  });

  useEffect(() => {
    if (
      !timeLeft ||
      !(
        typeof generateCodeData?.data?.attributes?.isRegisteredUser ===
        'boolean'
      )
    ) {
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft, generateCodeData?.data?.attributes?.isRegisteredUser]);

  useEffect(() => {
    setTimeLeft(30);
  }, [generateCodeData?.data]);

  const handleGenerateOtp = (event: any) => {
    event.preventDefault();
    const formData = new FormData();
    if (phone.length > 0) {
      formData.append('verification[phone]', phone);
    }
    if (email.length > 0) {
      formData.append('verification[email]', email);
    }
    //@ts-ignore
    generateCodeMutate(formData);
  };

  const handleLogin = (event: any) => {
    event.preventDefault();
    const formData = new FormData();
    if (phone) {
      formData.append('login[email_or_mobile]', phone.trim());
    }
    if (email) {
      formData.append('login[email_or_mobile]', email.trim());
    }

    formData.append('login[verification_code]', otp);
    loginMutate(formData);
  };

  const handleSignup = (event: any) => {
    event.preventDefault();
    const formData = new FormData();
    const [firstName, ...lastName] = name.split(' ');
    if (phone) {
      formData.append('login[email_or_mobile]', phone.trim());
    }
    if (email) {
      formData.append('login[email_or_mobile]', email.trim());
    }
    formData.append('login[verification_code]', otp);
    formData.append('user[first_name]', firstName);
    if (lastName.length > 0) {
      formData.append('user[last_name]', lastName.join(' '));
    }
    loginMutate(formData);
  };

  const token = useMemo(() => {
    if (loginData?.meta) {
      return loginData.meta?.token;
    }
  }, [loginData]);

  const handleOccasionJoinWithCode = (token: any) => {
    if (token) {
      const formData = new FormData();
      formData.append('code', 'MIZOMUSIC');
      joinWithCodeMutate(formData);
    }
  };

  useEffect(() => {
    if (joinWithCodeData?.data) {
      navigate('/checkout', {
        state: {
          allTicketsWithPrice: state?.allTicketsWithPrice,
          token,
          name: name.length ? name : loginData?.data?.attributes?.name,
          phone
        }
      });
    }
  }, [joinWithCodeData, token]);

  useEffect(() => {
    handleOccasionJoinWithCode(token);
  }, [token]);

  const isPhoneEmpty = useMemo(() => {
    return !(phone.length === 10);
  }, [phone]);

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isEmailEmpty = useMemo(() => {
    return !validateEmail(email);
  }, [email]);

  const isEmailOrPhoneEmpty = useMemo(() => {
    return isPhoneEmpty && isEmailEmpty;
  }, [isPhoneEmpty, isEmailEmpty]);

  const isOtpEmpty = useMemo(() => {
    if (
      typeof generateCodeData?.data?.attributes?.isRegisteredUser === 'boolean'
    ) {
      return !(otp.length === 5);
    }

    return false;
  }, [otp, generateCodeData]);

  const isNameEmpty = useMemo(() => {
    if (
      typeof generateCodeData?.data?.attributes?.isRegisteredUser ===
        'boolean' &&
      !generateCodeData?.data?.attributes?.isRegisteredUser
    ) {
      return !(name.length > 3 && name.length < 21);
    }
    return false;
  }, [name, generateCodeData]);

  const errorMessage = useMemo(() => {
    if (
      typeof loginData?.errors === 'object' ||
      typeof loginData?.error === 'object'
    ) {
      //@ts-ignore
      return loginData?.meta?.message ?? 'Failed to Login';
    }
    if (isOtpEmpty) {
      return 'Please enter a valid OTP';
    }
    if (isEmailOrPhoneEmpty) {
      return 'Please enter a valid phone number or email';
    }
  }, [isEmailOrPhoneEmpty, isOtpEmpty, loginData]);

  console.log('errorMessage', errorMessage);

  const submitButtonDisabled = useMemo(() => {
    return (
      loginPending ||
      generateCodePending ||
      isEmailOrPhoneEmpty ||
      isOtpEmpty ||
      isNameEmpty
    );
  }, [
    loginPending,

    generateCodePending,
    generateCodeData,
    isEmailOrPhoneEmpty,
    isOtpEmpty,
    isNameEmpty
  ]);

  if (loginError || generateCodeError) {
    return <>Failed to load data</>;
  }

  if (state?.allTicketsWithPrice) {
    return (
      <Paper
        style={{ minHeight: '99vh', background: '#f5f5f7' }}
        variant="outlined">
        <GradientBackgroundContainer>
        <Container>
            <FloorPlan />
          </Container>
          <Container>
            <Grid style={{ padding: 20, width: '100%' }}>
              <MainHeader>
                <Grid>
                  <Typography variant="h6" style={{ color: 'green' }}>
                    Mizo Music Festival
                  </Typography>
                  <Grid container item wrap="nowrap">
                    <TodayOutlined style={{ marginRight: 6, color: 'red' }} />{' '}
                    <Typography style={{ color: 'red' }}>
                      29 December 2023
                    </Typography>
                    <Typography style={{ color: 'red' }}>
                      {' '}
                      | 02:00 PM Onwards
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography>INR {quanAndValue.amount}</Typography>
                  <Typography style={{ color: '#92999B' }} variant="body2">
                    Qty: {quanAndValue.quantity}
                  </Typography>
                </Grid>
              </MainHeader>
              <LoginSubContainer>
                <Grid flex={1} style={{ width: '100%' }}>
                  <Typography style={{ marginBottom: 10, fontWeight: 'bold' }}>
                    Confirm your account and pay
                  </Typography>
                  <Grid
                    container
                    item
                    direction="column"
                    style={{ maxWidth: 'fit-content' }}>
                    {phone.length === 0 ? (
                      <TextField
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        type="email"
                        label="Email ID"
                        variant="outlined"
                        error={isEmailOrPhoneEmpty}
                        disabled={
                          typeof generateCodeData?.data?.attributes
                            ?.isRegisteredUser === 'boolean'
                        }
                      />
                    ) : null}
                    {phone.length === 0 && email.length === 0 ? (
                      <Typography
                        textAlign="center"
                        style={{ margin: '10px 0px' }}>
                        OR
                      </Typography>
                    ) : null}
                    {email.length === 0 ? (
                      <TextField
                        value={phone}
                        type="tel"
                        onChange={(event) => setPhone(event.target.value)}
                        label="Phone Number"
                        error={isEmailOrPhoneEmpty}
                        variant="outlined"
                        disabled={
                          typeof generateCodeData?.data?.attributes
                            ?.isRegisteredUser === 'boolean'
                        }
                      />
                    ) : null}
                    {typeof generateCodeData?.data?.attributes
                      ?.isRegisteredUser === 'boolean' &&
                    !generateCodeData?.data?.attributes?.isRegisteredUser ? (
                      <>
                        <TextField
                          value={name}
                          label="Full Name"
                          error={isNameEmpty}
                          variant="outlined"
                          style={{ marginTop: 10 }}
                          onChange={(event) => setName(event.target.value)}
                        />
                      </>
                    ) : null}
                    {generateCodeData?.data?.attributes ? (
                      <TextField
                        error={isOtpEmpty}
                        value={otp}
                        label="Enter OTP"
                        variant="outlined"
                        style={{ marginTop: 10 }}
                        onChange={(event) => setOtp(event.target.value)}
                      />
                    ) : null}
                  </Grid>

                  {errorMessage ? (
                    <Typography
                      variant="body2"
                      style={{ margin: '10px 0px', color: 'maroon' }}>
                      {errorMessage}
                    </Typography>
                  ) : null}
                  <Typography
                    style={{
                      color: '#92999B',
                      maxWidth: 300,
                      margin: '10px 0px'
                    }}
                    variant="body2">
                    {typeof generateCodeData?.data?.attributes
                      ?.isRegisteredUser === 'boolean'
                      ? 'Please enter the One Time Password sent to your mobile number'
                      : 'If you don’t have an account yet, we’ll create one for you'}
                  </Typography>
                  {typeof generateCodeData?.data?.attributes
                    ?.isRegisteredUser === 'boolean' ? (
                    timeLeft ? (
                      <Typography
                        variant="body2"
                        style={{
                          color: '#92999B',
                          maxWidth: 350,
                          margin: '10px 0px'
                        }}>
                        You can request OTP again in {timeLeft} sec
                      </Typography>
                    ) : (
                      <Grid>
                        <Button
                          onClick={handleGenerateOtp}
                          variant="outlined"
                          style={{
                            marginBottom: 10,
                            borderColor: 'black',
                            color: 'black',
                            width: 170
                          }}>
                          Resend OTP
                        </Button>
                      </Grid>
                    )
                  ) : null}
                  <Button
                    onClick={
                      typeof generateCodeData?.data?.attributes
                        ?.isRegisteredUser === 'boolean'
                        ? generateCodeData?.data?.attributes?.isRegisteredUser
                          ? handleLogin
                          : handleSignup
                        : handleGenerateOtp
                    }
                    variant="contained"
                    disabled={submitButtonDisabled}
                    style={{
                      backgroundColor: 'black',
                      color: 'white',
                      width: 170
                    }}>
                    {generateCodePending || loginPending
                      ? 'Loading...'
                      : typeof generateCodeData?.data?.attributes
                          ?.isRegisteredUser === 'boolean'
                      ? generateCodeData?.data?.attributes?.isRegisteredUser
                        ? 'Login'
                        : 'Signup'
                      : 'Verify With OTP'}
                  </Button>
                </Grid>
                <Grid
                  flex={1}
                  style={{
                    boxShadow: '0 0.5px 2px rgba(21, 134, 21, 0.7)',
                    padding: '10px 20px',
                    borderRadius: 10,
                    width: '100%',
                    borderLeft: '3px solid green'
                  }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 'bold', color: 'green' }}>
                    Order Summary
                  </Typography>
                  <Grid
                    container
                    item
                    justifyContent="space-between"
                    style={{ marginTop: 10 }}>
                    <Typography>Ticket Amount</Typography>
                    <Typography>INR {quanAndValue.amount}</Typography>
                  </Grid>
                  <Grid>
                    {state.allTicketsWithPrice?.map((item: any) => {
                      return (
                        <Grid container item justifyContent="space-between">
                          <Typography variant="body2">
                            {item.quantity} X {item.name}
                          </Typography>
                          <Typography>INR {item.amount}</Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <hr />
                  <Grid container item justifyContent="space-between">
                    <Typography>Total Amount</Typography>
                    <Typography>INR {quanAndValue.amount}</Typography>
                  </Grid>
                </Grid>
              </LoginSubContainer>
            </Grid>
          </Container>
          
        </GradientBackgroundContainer>
      </Paper>
    );
  }
  return null;
};

export default Login;
