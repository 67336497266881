import { TodayOutlined } from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { Container, TicketItemContainer } from './index.style';
import { Link } from 'react-router-dom';
import { GradientBackgroundContainer } from '../Checkout/index.style';
import FloorPlan from '../../Components/FloorPlan';

const Tickets = () => {
  const [ticketInputs, setTicketInputs] = useState<any>({});
  const queryClient = useQueryClient();
  const { isPending, isError, data, error } = useQuery({
    queryKey: ['tickets'],
    queryFn: () => {
      return fetch(
        'https://api.sociana.in/api/v2/oc/144/direct/ticket/get_active_occasion_tickets'
      ).then((res) => res.json());
    }
  });

  const tickets = useMemo(() => {
    if (data?.included) {
      return data.included
        .map((item: any) => {
          if (item.type === 'ticketVariants') {
            return { ...item.attributes, id: item.id };
          }
        })
        .filter((item: any) => !!item);
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (tickets.length) {
      const prevTicketInputs: any = { ...ticketInputs };
      tickets.forEach((item: any) => {
        prevTicketInputs[item.id] = 0;
      });
      setTicketInputs(prevTicketInputs);
    }
  }, [tickets]);

  const handleTicketSelectChange = (event: SelectChangeEvent, id: any) => {
    console.log('what are change', event, id);
    const value = event.target.value;
    const prevTicketInputs: any = { ...ticketInputs };
    prevTicketInputs[id] = value;
    setTicketInputs(prevTicketInputs);
  };

  const totalTicketCount: any = useMemo(() => {
    return Object.values(ticketInputs).reduce(
      (partialSum: any, a: any) => partialSum + a,
      0
    );
  }, [ticketInputs]);

  const allTicketsWithPrice: any = useMemo(() => {
    let allTicketsWithPrice: any = [];
    Object.keys(ticketInputs).forEach((id: any) => {
      const ticketQuant = ticketInputs[id];
      const ticketData = tickets.find((item: any) => item.id === id);
      const ticketAmmount = (ticketData.basePrice / 100) * ticketQuant;

      if (ticketAmmount > 0) {
        allTicketsWithPrice.push({
          name: ticketData.title,
          amount: ticketAmmount,
          quantity: ticketQuant,
          ticketInfo: ticketData,
          id
        });
      }
    });

    return allTicketsWithPrice;
  }, [ticketInputs]);

  const totalTicketsPrice = useMemo(() => {
    let total = 0;
    allTicketsWithPrice.forEach((item: any) => {
      total += item.amount;
    });
    return total;
  }, [allTicketsWithPrice]);

  console.log('what is data', tickets, ticketInputs);

  if (isPending) {
    return <>Loading...</>;
  }

  if (isError) {
    return <>Failed to Load Data</>;
  }
  console.log('ticket', tickets);
  return (
    <Paper
      style={{ minHeight: '99vh', background: '#f5f5f7' }}
      variant="outlined">
      <GradientBackgroundContainer>
      <Container>
          <FloorPlan />
        </Container>
        <Container>
          <Grid flex={4} style={{ padding: 10 }}>
            <Typography variant="h6" style={{ color: 'green' }}>
              Mizo Music Festival
            </Typography>
            <Grid container item wrap="nowrap">
              <Grid container item>
                <TodayOutlined style={{ marginRight: 6, color: 'red' }} />{' '}
                <Typography style={{ color: 'red' }}>
                  29 December 2023
                </Typography>
                <Typography style={{ color: 'red' }}>
                  {'  '}| 02:00 PM Onwards
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ maxHeight: 500, overflowY: 'scroll' }}>
              {tickets.map((item: any) => {
                return (
                  <TicketItemContainer>
                    <Grid flex={2} style={{ marginBottom: 10 }}>
                      <Typography>{item.title}</Typography>

                      <Typography style={{ fontWeight: 'bold' }}>
                        ₹{item.basePrice / 100}
                      </Typography>
                    </Grid>
                    <Grid
                      flex={2}
                      container
                      item
                      wrap="nowrap"
                      alignItems="center">
                      <Grid flex={1} container item justifyContent="flex-end">
                        <FormControl size="small">
                          <InputLabel>{`Quantity: ${
                            ticketInputs[item.id]
                          }`}</InputLabel>
                          <Select
                            size="small"
                            style={{ minWidth: 155, borderColor: 'green' }}
                            value={ticketInputs[item.id]}
                            label={`Quantity: ${ticketInputs[item.id]}`}
                            onChange={(event: SelectChangeEvent) =>
                              handleTicketSelectChange(event, item.id)
                            }>
                            {Array.from(Array(item.maximumBuy).keys()).map(
                              (item: any) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid flex={1} container item justifyContent="flex-end">
                        <Typography>
                          ₹{ticketInputs[item.id] * (item.basePrice / 100)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TicketItemContainer>
                );
              })}
            </Grid>
          </Grid>
          <Grid flex={2} style={{ padding: 10 }}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: 20 }}>
              <Typography>Ticket Quantity</Typography>
              <Typography>{totalTicketCount}</Typography>
            </Grid>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center">
              <Typography>Ticket Amount</Typography>
              <Typography>₹{totalTicketsPrice}</Typography>
            </Grid>
            <Grid>
              {allTicketsWithPrice?.map((item: any) => {
                return (
                  <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap">
                    <Typography variant="body2" style={{ color: '#7b7878' }}>
                      {item.quantity} x {item.name}
                    </Typography>
                    <Typography variant="body2" style={{ color: '#7b7878' }}>
                      ₹{item.amount}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            <hr />
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              marginBottom="20px">
              <Typography>Total Amount</Typography>
              <Typography>₹{totalTicketsPrice}</Typography>
            </Grid>
            <Grid>
              <Link
                to={totalTicketsPrice > 0 ? '/login' : ''}
                state={{ allTicketsWithPrice }}
                style={{
                  color: 'white',
                  textDecoration: 'none',
                  cursor: totalTicketsPrice > 0 ? 'pointer' : 'not-allowed'
                }}>
                <Grid>
                  <Button
                    disabled={totalTicketsPrice > 0}
                    variant="contained"
                    style={{
                      backgroundColor: 'black',
                      color: 'white',
                      width: '100%',
                      cursor: totalTicketsPrice > 0 ? 'pointer' : 'not-allowed'
                    }}>
                    Checkout
                  </Button>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        </Container>
       
      </GradientBackgroundContainer>
    </Paper>
  );
};

export default Tickets;
