export const TERMS = [
    'Venue and club rules apply.',
    'Strict COVID-19 Safety Protocols apply.',
    'Organiser/venue reserves the right to deny entry at any time as a precautionary measure to uphold the highest level of health and safety for everyone.',
    'Temperature checks might be taken by any of the venue/restaurant representatives prior, during, and after, in the club. The event organiser/venue will make a decision on access to the restaurant/venue based on your health conditions',
    'Do not buy tickets from anyone except online on skillboxes.com',
    "Please remember to carry a valid government-issued photo ID proof (Driver's License/Aadhar Card/PAN Card/Voter Card/Passport). You may not be permitted to enter in the absence of one.",
    'The venue and schedule are subject to change.',
    'This ticket permits entry to the event venue only. Any pre or after parties may have other ticketing requirements and may be sold separately.',
    'Security procedures, including frisking, remain the right of the management.',
    'No refund/replacement on a purchased ticket. Tickets you purchase are for personal use. You must not transfer (or seek to transfer) the tickets in breach of the applicable terms. A breach of this condition will entitle us to cancel the tickets without prior notification, refund, compensation or liability.',
    'Please do not carry dangerous or potentially hazardous objects including but not limited to weapons, knives, guns, fireworks, laser devices and bottles to the venue. We may have to eject these things (with or without you) from the venue.',
    'The sponsors/bands/organizers/management are not responsible for, including but not limited to, any injury or damage that may occur during or at the event.',
    'No liability or claims that may arise due to the consumption or intake of any food or drink or any other consumption will be entertained by the management.',
    'Parking near or at the event premises is at the risk of the vehicle owner.',
    "The holder of this ticket grants organizers the right to use, in perpetuity, all or any part of the recording of any video or still footage made of the holder's appearance on any channel or magazine for broadcast in any and all media globally and for advertising, publicity and promotions relating hereto without any further approval of theirs.",
    "The management reserves the exclusive right without refund or other recourse, to refuse admission to anyone who is found to be in breach of these terms and conditions including, if necessary, ejecting the holder/s of the ticket from the venue after they have entered the premises."
]