import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background: white;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 4px 4px 2.5px 1.5px #000000;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const FloorPlanContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: white;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;


export const PriceContainer = styled.div`
  position: absolute;
  top: 20px;
  right: -50px;
  background: black;
  padding: 5px 25px;

  @media (max-width: 1000px) {
    position: unset;
    top: unset;
    right: unset;
    margin-top: 15px;
  }
`;

export const MapContainer = styled.div`
  padding: 25px;
  max-height: 350px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media (max-width: 650px) {
    flex-direction: column;
    max-height: 100%;
    justify-content: center;
    text-align: center;
    width: auto;
  }
`;

export const HeaderText = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-size: 5rem;
  background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
