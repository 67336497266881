import styled from "styled-components";

export const LoginSubContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-top:20px;
    width:100%;

    @media (max-width:720px) {
        align-items:start;
        flex-direction:column;
        gap:20px;
    }
`