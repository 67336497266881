import { Grid } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    flex-direction:row;
    width:80%;
    margin-left:auto;
    margin-right:auto;
    background:white;
    margin-top:30px;
    margin-bottom:30px;
    border-radius:5px;
    padding:20px;
    box-shadow:0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    @media (max-width: 1000px) {
    flex-direction: column;
  }
  
`
export const TicketItemContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    border-bottom: 1px grey solid;
    padding: 10px;
    margin: 5px 0px;

    @media (max-width:600px) {
      flex-direction:column;
      justify-content:flex-start;
      align-items:start;
    }
`
