import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter
} from 'react-router-dom';
import Home from './Pages/Home';
import Tickets from './Pages/Tickets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Login from './Pages/Login';
import Checkout from './Pages/Checkout';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route index element={<Home />} />
            <Route path="tickets" element={<Tickets />} />
            <Route path="login" element={<Login />} />
            <Route path="checkout" element={<Checkout />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
