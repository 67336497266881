import { Button, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Container } from '../Home/index.style';
import { useLocation } from 'react-router';
import { CheckCircleOutline, TodayOutlined } from '@mui/icons-material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GradientBackgroundContainer, MainHeader } from './index.style';
import FloorPlan from '../../Components/FloorPlan';

const Checkout = () => {
  const { state } = useLocation();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  const { isPending, isError, data, error, mutate } = useMutation({
    mutationFn: (formData: any) => {
      return fetch(
        'https://api.sociana.in/api/v1/create_multiple_ticket_variant_order',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${state?.token}`
          }
        }
      ).then((res) => res.json());
    }
  });

  const {
    isPending: purchasePending,
    isError: isPurchaseError,
    data: purchaseData,
    error: purchaseError,
    mutate: purchaseMutate
  } = useMutation({
    mutationFn: (formData: any) => {
      return fetch(
        'https://api.sociana.in/api/v1/create_multiple_ticket_purchases',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${state?.token}`
          }
        }
      ).then((res) => res.json());
    }
  });

  const quanAndValue = useMemo(() => {
    const res = {
      quantity: 0,
      amount: 0
    };
    state?.allTicketsWithPrice?.forEach((item: any) => {
      res.quantity += item.quantity;
      res.amount += item.amount;
    });

    return res;
  }, [state?.allTicketsWithPrice]);

  const handleRazorpayDismiss = async () => {
    const failedAttempt = {
      failed_registration: {}
    };

    console.log('failed to pay');
    // failedAttempt.failed_registration.message = 'User cancelled registration';
    // setLoading(false);
    // await request('/api/v2/failed_registrations/?isb_version=2021', {
    //   method: 'post',
    //   body: failedAttempt
    // });
  };

  const handlePayment = (response: any) => {
    try {
      const { razorpay_order_id, razorpay_payment_id, razorpay_signature } =
        response;

      const formData = new FormData();
      state?.allTicketsWithPrice?.forEach((item: any) => {
        formData.append('ticket_purchase[][ticket_variant_id]', item.id);
        formData.append('ticket_purchase[][purchase_quantity]', item.quantity);
        formData.append(
          'ticket_purchase[][total_amount]',
          String(Number(item.amount) * 100)
        );
      });
      formData.append('razorpay_order_id', razorpay_order_id);
      formData.append('razorpay_payment_id', razorpay_payment_id);
      purchaseMutate(formData);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const handleOrder = () => {
    try {
      if (data?.data?.attributes?.razorpayOrderId) {
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: quanAndValue.amount * 100,
          name: 'Mizo Music Festival',
          description: 'Ticket order',
          // image: Logo,
          order_id: data.data.attributes.razorpayOrderId,
          handler: handlePayment,
          prefill: {
            name: state.name,
            contact: state.phone
          },
          notes: {},
          theme: {
            color: '#000853'
          },
          modal: {
            ondismiss: handleRazorpayDismiss
          }
        };
        //@ts-ignore
        const rzp1 = new Razorpay(options);
        rzp1.open();
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  useEffect(() => {
    if (!data?.data?.attributes?.razorpayOrderId) {
      const formData = new FormData();
      state?.allTicketsWithPrice?.forEach((item: any) => {
        formData.append('razorpay_orders[][ticket_variant_id]', item.id);
        formData.append('razorpay_orders[][purchase_quantity]', item.quantity);
        formData.append(
          'razorpay_orders[][total_amount]',
          String(Number(item.amount) * 100)
        );
      });
      mutate(formData);
    }
  }, [state?.token, state?.allTicketsWithPrice]);

  const puchasedTickets = useMemo(() => {
    if (purchaseData?.data) {
      return purchaseData?.data?.map((item: any) => {
        const ticketData = purchaseData.included.find(
          (el: any) =>
            item.relationships.ticketVariant?.data?.id === el.id &&
            el.type === 'ticketVariants'
        );
        return {
          ticket: { ...item.attributes },
          variant: { ...ticketData.attributes }
        };
      });
    }
    return [];
  }, [purchaseData]);

  console.log('what is purchase data', puchasedTickets);

  return (
    <GradientBackgroundContainer>
      <Container>
        <FloorPlan />
      </Container>
      <Container>
        <Grid style={{ padding: 20, width: '100%' }}>
          <MainHeader>
            <Grid>
              <Typography variant="h6" style={{ color: 'green' }}>
                Mizo Music Festival
              </Typography>
              <Grid container item wrap="nowrap">
                <TodayOutlined style={{ marginRight: 6, color: 'red' }} />{' '}
                <Typography style={{ color: 'red' }}>
                  29 December 2023
                </Typography>
                <Typography style={{ color: 'red' }}>
                  {' '}
                  | 02:00 PM Onwards
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Typography>INR {quanAndValue.amount}</Typography>
              <Typography style={{ color: '#92999B' }} variant="body2">
                Qty: {quanAndValue.quantity}
              </Typography>
            </Grid>
          </MainHeader>
          <Grid style={{ marginTop: 20 }}>
            <Typography
              variant="h6"
              style={{ fontWeight: 'bold', color: 'green' }}>
              Hi {state.name}
            </Typography>
            <Typography>Following is your order summary</Typography>
            <Grid
              flex={1}
              style={{
                boxShadow: '0 0.5px 2px rgba(21, 134, 21, 0.7)',
                padding: '10px 20px',
                borderRadius: 10,
                width: '100%',
                marginTop: 20,
                borderLeft: '3px solid green'
              }}>
              <Typography
                variant="h6"
                style={{ fontWeight: 'bold', color: 'green' }}>
                Order Summary
              </Typography>
              <Typography variant="body2">
                Order Id: {data?.data?.attributes?.razorpayOrderId}
              </Typography>
              <Grid
                container
                item
                justifyContent="space-between"
                style={{ marginTop: 10 }}>
                <Typography>Ticket Amount</Typography>
                <Typography>INR {quanAndValue.amount}</Typography>
              </Grid>
              <Grid>
                {state.allTicketsWithPrice?.map((item: any) => {
                  return (
                    <Grid container item justifyContent="space-between">
                      <Typography variant="body2" flex={2}>
                        {item.quantity} X {item.name}
                      </Typography>
                      <Typography textAlign="end" flex={1}>
                        INR {item.amount}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
              <hr />
              <Grid container item justifyContent="space-between">
                <Typography>Total Amount</Typography>
                <Typography>INR {quanAndValue.amount}</Typography>
              </Grid>
            </Grid>
            {purchaseData?.data && !isPurchaseError ? (
              <Grid
                container
                item
                wrap="nowrap"
                alignItems="center"
                direction="column"
                justifyContent="center"
                style={{ margin: '20px 0px' }}>
                <CheckCircleOutline
                  style={{ color: 'green', fontSize: 30, marginRight: 10 }}
                />
                <Typography variant="h6" style={{ color: 'green' }}>
                  Hey, your payment is successful and we have sent you the
                  tickets on your email or whatsapp.
                  <br />
                  You can Download the tickets from below as well. <br />
                  If you have not recieved the ticket please contact support at
                  +917980745922.
                </Typography>
                <Grid container item>
                  {puchasedTickets.map((item: any) => {
                    return (
                      <Grid
                        container
                        item
                        direction="column"
                        alignItems="center"
                        flex={1}
                        style={{ padding: 20, margin: 10 }}>
                        <Typography>
                          {item.ticket.quantity}x {item.variant.title}
                        </Typography>
                        <img
                          style={{ height: 100, width: 100 }}
                          src={`https://api.sociana.in/${item?.ticket.qrcodeUrl}`}
                        />
                        <Typography></Typography>
                        <Typography variant="body2">
                          Reference Id: {item?.ticket.referenceId}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            ) : (
              <>
                <Typography
                  variant="body2"
                  style={{ color: 'grey', margin: '10px 0px' }}>
                  <sup>*</sup>In case if you have any issue with the payment
                  please contact +917980745922.
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: 'red', margin: '10px 0px' }}>
                  <sup>*</sup>Please <strong>DO NOT REFRESH</strong> or{' '}
                  <strong>EXIT</strong> the page during payment process.
                </Typography>
                <Button
                  onClick={handleOrder}
                  variant="contained"
                  disabled={purchasePending || isPending}
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    marginTop: 20,
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}>
                  {purchasePending || isPending
                    ? 'Loading...'
                    : 'Confirm and Pay'}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </GradientBackgroundContainer>
  );
};

export default Checkout;
