import { AddLocationAltOutlined, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Typography
} from '@mui/material';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  Container,
  FloorPlanContainer,
  HeaderText,
  MapContainer,
  PriceContainer
} from './index.style';
import { TERMS } from './constant';
import { Link } from 'react-router-dom';
import { GradientBackgroundContainer } from '../Checkout/index.style';
import SEAN_KING_HORIZONTAL from '../../assets/seanKingHorizontal2.jpeg';
import SEAN_KING_HORIZONTAL1 from '../../assets/seanKingHorizontal1.jpeg';
import EVENT_RIGHT_IMAGE from '../../assets/rightsectionimg.jpg';
import Image1 from "../../assets/ShawnThangsten.png";
import Image2 from "../../assets/newspaper.jpg";
import RIGHTImage from "../../assets/homeImage.jpeg";
import GetSETGOmage from "../../assets/getsetgo.jpg";
import FloorPlan from '../../Components/FloorPlan';
const Home = () => {
  return (
    <Paper
      style={{ background: '#f5f5f7', minHeight: '99vh' }}
      variant="outlined">
      <GradientBackgroundContainer>
        {/* <HeaderText>Venga Boys</HeaderText> */}
        <Container>
          <Grid flex={5} style={{boxShadow:"3px 0 2.5px 1px gray"}}>
            <Carousel autoPlay interval={5000} infiniteLoop showThumbs={false} >
              <div>
                <img
                  style={{ objectFit: 'contain' }}
                  alt="event_image_3"
                  src={Image1}
                />
              </div>
              <div>
                <img
                  alt="event_image_2"
                  style={{ width:"100%", objectFit: 'contain' }}
                  src={Image2}
                  // src={SEAN_KING_HORIZONTAL1}
                  // src="https://1840729241.rsc.cdn77.org/media/event_cover/OFBtUWVaUXNlUkpZTmdmMVJsRHlnZz09/anUvZklscG9wVElZc1h0aTRJZmtGZz09/resize/1200x628/17003168025LyFDTqfNKUAO0d8.png.webp"
                />
              </div>
            </Carousel>
          </Grid>
          <Grid
            container
            item
            direction="column"
            flex={2}
            style={{ padding: '10px 20px 0 20px' }}>
            <Grid
              container
              item
              direction="column"
              style={{ textAlign:"center" }}>
                <div style={{width:"100%",textAlign:'center'}}>
                <img
                  style={{objectFit: 'contain', width:"80%"}}
                  alt="event_image_3"
                  src={RIGHTImage}
                />
              </div>
                {/* <div style={{width:"200px",textAlign:'center',margin:"5px 0"}}>
                <img
                  style={{objectFit: 'contain', width:"100%",height:"100%" }}
                  alt="event_image_3"
                  src={GetSETGOmage}
                />
              </div> */}
              {/* <Typography style={{fontSize:"20px",fontWeight:"600",padding:"20px 0 5px 0"}}>2:00 PM Onwards</Typography>
              <Typography style={{fontSize:"20px",fontWeight:"600",padding:"5px 0 20px 0"}}>29 December 2023</Typography> */}
              {/* <Button
                size="small"
                variant="text"
                target="_blank"
                href="https://www.google.com/calendar/event?action=TEMPLATE&text=Aizawl%20Winter%20Fest%20(AWF)&location=PPH9+CF9,%20Dawrpui,%20Aizawl,%20Mizoram%20796001,%20India&dates=20231207T083000Z/20231207T182900Z"
                style={{ color: '#007bff', maxWidth: 150, padding: 0 }}>
                Add to Calender
              </Button> */}
              {/* <PriceContainer>
                <Typography style={{ color: 'white', textAlign: 'center' }}>
                  ₹ 800 - 7020
                </Typography>
              </PriceContainer> */}
            </Grid>
            {/* <Grid style={{ marginTop: 25 }}>
              <Typography variant="body2" style={{ color: '#ff4a56' }}>
                Music Events
              </Typography>
              <Typography
                variant="h5"
                style={{ fontWeight: 'bolder', marginTop: 15 }}>
                Mizo Music Festival
              </Typography>
            </Grid> */}
            <Grid style={{ marginTop: 'auto', textAlign:"center" }}>
              {/* <Grid container item wrap="nowrap" style={{ marginBottom: 20 }}>
                <AddLocationAltOutlined
                  style={{ fontSize: 50, color: 'lightgreen', marginRight: 10 }}
                />
                <Typography variant="h6">AR Lammual Stadium, Aizawl</Typography>
              </Grid> */}
              
              {/* <div style={{width:"100%",textAlign:'center',margin:"10px 0 25px"}}>
                <img
                  style={{objectFit: 'contain', width:"80%", borderRadius:"50%" }}
                  alt="event_image_3"
                  src={RIGHTImage}
                />
              </div> */}
              <Link
                to="/tickets"
                style={{ color: 'white', textDecoration: 'none'}}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    width: '80%',
                    padding:"15px 0",
                    fontSize:"20px",
                    boxShadow:"3px 3px 2.5px 1.5px #f99f1b"
                  }}>
                  Book Now
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid style={{ padding: 15 }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: 'bolder',
                marginBottom: 20,
                color: '#f99f1b'
              }}>
              Description
            </Typography>
            <Grid>
              <Typography style={{ margin: '30px 0px', color: '#f99f1b' }}>
                ABOUT MARK EVENT AND ENTERTAINMENT
              </Typography>
              <Typography>
                Mark Events And Entertainment is a leading event management
                agency in Mizoram , a unit of Mark Advertising established in
                2007 based in Aizawl, Mizoram.
              </Typography>
              {/* <Typography style={{ marginTop: 30, color: 'green' }}>
                National Act and Local Talent:
              </Typography> */}
              <Typography>
                Mark Events And Entertainment has worked with various government
                departments as well corporate companies. It has managed state
                wide events, some recent ones being Eat Right Mela 2022,
                International Tourism Mart 2022, G20, Anthurium Festival 2023 to
                name a few.
              </Typography>
              {/* <Typography style={{ marginTop: 30, color: 'green' }}>
                Mizo Music Festival is brought to you by Elle Media a
                distinguished events and productions company with a legacy of
                creating spectacular shows. Formerly known as Shooting Star
                Events, the company has a rich history of hosting successful
                events in prominent cities across India, including Pune,
                Bangalore, and Mumbai.
              </Typography> */}
              <Typography style={{ marginTop: 30, color: '#f99f1b' }}>
                ABOUT THE CONCERT
              </Typography>
              <Grid style={{ marginTop: 30 }}>
                <Typography>
                  <strong>Venue :</strong> AR Ground, Lammual{' '}
                </Typography>
                <Typography>
                  <strong>Date :</strong> 29.12.2023
                </Typography>
                <Typography>
                  <strong>Time :</strong> 4:00pm – 10:00pm
                </Typography>
                <Typography>
                  The Sean Kingston Concert will be organised on the 29th of
                  December in Aizawl, Mizoram at AR Ground, Lammual located in
                  the heart of the city. The AR Ground, Lammual can hold a
                  capacity of 20,000 people. This concert is believed to attract
                  locals as well as tourists and visitors from other states
                  given that he is an award winning international artist whose
                  song had been sung and played all over the world. The concert
                  will begin at 4:00pm which will go on till 10:00pm on 29th
                  December, 2023. The opening act of this concert will feature
                  10 prominent local artists as opening acts
                </Typography>
              </Grid>

              {/* <Typography style={{ marginTop: 30, color: 'green' }}>
                Join us for Mizo Music Festival:
              </Typography> */}
              {/* <Typography>
                Immerse yourself in the rhythm of the music, the warmth of the
                winter season, and the collective spirit of celebration at Mizo
                Music Festival. Whether you're a fan of international
                chart-toppers or eager to discover local talent, this festival
                promises an unparalleled experience for music enthusiasts and
                culture aficionados alike.
              </Typography>
              <Typography style={{ margin: '30px 0px' }}>
                Save the date and be part of a winter celebration like never
                before! Stay tuned for more updates and get ready to create
                lasting memories at Mizo Music Festival 2023.
              </Typography> */}
              <Button variant="outlined" style={{ marginTop: 30 }}>
                Music Festival
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <MapContainer>
            <Grid flex={1}>
              <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>
                AR Lammual Stadium
              </Typography>
              <Typography variant="body2">
                PPH9+CF9, Dawrpui, Aizawl, Mizoram 796001, India
              </Typography>
              <Button
                size="small"
                variant="text"
                href="https://maps.google.com/?daddr=23.7285324,92.718739"
                target="_blank"
                style={{
                  color: '#007bff',
                  maxWidth: 125,
                  padding: 0,
                  marginTop: 10
                }}>
                Get Derections
              </Button>
            </Grid>
            <Grid flex={1}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.5283210340367!2d92.71616407501399!3d23.72853237868534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x374d94c819513369%3A0xdbad63f165344259!2sLammual!5e0!3m2!1sen!2sin!4v1700757028338!5m2!1sen!2sin"
                style={{ border: 0, minHeight: 300, width: '100%' }}
                loading="lazy"></iframe>
            </Grid>
          </MapContainer>
        </Container>
        <Container>
          <FloorPlanContainer>
          <FloorPlan />
          </FloorPlanContainer>
        </Container>
        <Container>
          <Grid>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6" style={{ fontWeight: 'bolder' }}>
                  TERMS & CONDITIONS
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {TERMS.map((item) => (
                    <li>
                      <Typography style={{ margin: '5px 0px' }}>
                        {item}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Container>
      </GradientBackgroundContainer>
    </Paper>
  );
};

export default Home;
