import { Grid, Typography } from '@mui/material';
import React from 'react';
import "../floorplan.css";
import FloorplanImg from '../../assets/floorplan2.png';
const FloorPlan = () => {

  return (
    <>
    <Grid container item style={{ width: '100%' }}>
      <Typography style={{ padding: 10, fontWeight: 'bolder' }} variant="h6">
        Floor Plan
      </Typography>
      <img src={FloorplanImg} style={{ width: '100%' }} />
    </Grid>
    {/* <Grid container item style={{ width: '100%' }}>
      <Typography className='floorplan_text' variant="h6">
        Floor Plan
      </Typography>
      <img src={FloorplanImg} className='floorplan_img' />
    </Grid> */}
    </>
  );
};

export default FloorPlan;
