import styled, { keyframes } from "styled-components";

export const MainHeader = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;

    @media (max-width:600px) {
        flex-direction:column;
        align-items:start;
    }
`

export const Gradient = keyframes`
0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
`

export const GradientBackgroundContainer = styled.div`
    width: 100%;
    height:100%;
    display:flow-root;
    min-height:100vh;
    background: #f99f1b;
    background-size: 400% 400%;
    animation: ${Gradient} 15s ease infinite;
`



  
 

  

